import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"

const banner_title  = ['and Conditions ', 3000]



const termsConditions = () => { 

    return (
<Layout>
<Seo title="Our Terms & Conditions " />
<Banner className = "service" title = {banner_title} sticky_first = "General Terms   " sticky_second  = "" />

<section className = "full_details">
<div className="container">

<h2>OVERVIEW</h2>

<p>This website is operated by EstoreCompany LLC. Throughout the site, the terms “we”, “us” and “our” refer to EstoreCompany LLC. EstoreCompany LLC offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
<p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available on purchase order in form of invoice or agreement . These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. </p>

<h2>GENERAL CONDITIONS</h2>
<p>
We reserve the right to refuse service to anyone for any reason at any time. 
You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us. 
The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
</p>

<h2>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION 
</h2>
<p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. 
</p>
<p>
This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site. 
</p>
<h2>LIMITATIONS, RELEASE AND INDEMNITY </h2>
<p>
Based upon the nature of client involvement and the required approvals throughout a project, The Client acknowledges that Estore Company cannot and does not guarantee the success of a project, including but not limited to the number of persons accessing a website/application nor the results of such access. Furthermore, Estore Company cannot and will not guarantee any "life expectancy" of said website/application, due to such circumstances outside of its control such as influences by third-party software,hardware, Internet browsers and the like. 
</p>
<p>
ESTORE COMPANY  SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, BREACH OF WARRANTY, INTERRUPTION OF BUSINESS, LOST PROFITS, REVENUE OR OPPORTUNITY, CONSEQUENTIAL OR PUNITIVE DAMAGES, EXPENSES, COSTS OR LOSSES OF ANY NATURE OR KIND WHATSOEVER DIRECTLY OR INDIRECTLY RESULTING FROM OR ARISING OUT OF THE SERVICES PERFORMED HEREUNDER INCLUDING NEGLIGENCE AND/OR GROSS NEGLIGENCE. In no event shall Estore Company 's liability for any direct damages resulting from the failure of any services that were performed hereunder exceed the amount paid by The Client to Estore Company hereunder. 
</p>
<p>
ESTORE COMPANY EXPRESSLY DISCLAIMS ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY OR WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. The Client hereby releases and discharges Estore Company , its directors, officers, employees, representatives, agents, successors and assigns from and against any and all claims and demands of any nature or kind whatsoever arising out of the design, development or content of the application(s) and procedures proposed or agreed upon if an Agreement(s) executed by the parties hereto, with the exception being for any damages or loss caused by fraud, criminal activity, dishonesty, willful behavior or abuse of nonpublic secured data base information by Estore Company , its directors, officers, employees, representatives, agents and successors and assigns. The Client furthermore agrees to indemnify, defend and hold harmless Estore Company  and its directors, officers, employees, representatives, agents, successors and assigns from any and all claims, demands, cause or causes of action or losses of any nature whatsoever, including attorney fees, expenses and costs, arising out of the optimization and marketing procedures proposed herein, with the exception of any damages or loss caused by fraud, criminal activity, dishonesty, willful behavior, or abuse of nonpublic secured data base information by Estore Company , its directors, officers, employees, representatives, agents and successors and assigns. 
</p>
<h2>AUTHORIZATION</h2>
<p>
The Client agrees to allow Estore Company to use the The Client project as part of Estore Company 's portfolio. This may include, but not be limited to, placement of the The Client project in Estore Company 's printed material, on Estore Company 's website or in marketing campaigns. 
</p>
<h2>PROJECT INVESTMENT & TARGET SCHEDULE </h2>
<p>
The Client, hereby acknowledges and agrees to the project investment and target schedule, as it specifically relates to an Agreement, as outlined in an individual and/or specific Statement of Work (SOW) or Proposal. Furthermore, The Client understands and acknowledges that the project investment is based upon an estimated amount of time and materials needed to complete the project as specified within the SOW or Proposal, and that any change in scope and/or requested/required changes in deliverables, may cause the project investment to be altered as an increase and/or decrease in total completion cost. This project, and all project work by Estore Company , will be completed on a target schedule basis which is an estimated time for completion schedule. Estore Company shall work diligently to complete the project within the estimated time for completion scheduled. However, Estore Company shall assume no responsibility for delays caused by The Client and/or any natural cause affecting the general business market. 
</p>
<h2>LATE PENALTY </h2>
<p>
All fees for Estore Company goods and services shall be paid by the Client in United States dollars within fourteen (14) days of the date of the invoice. If any invoice is (45) forty-five days or more overdue, in addition to any other rights and remedies (including but not limited to termination of the Client in accordance with the terms set forth herein), Estore Company  reserves the right to charge interest on the delinquent amount equal to one and one half percent (1.5%) per month on any outstanding balance or the maximum permitted by law and refer the matter for collection or legal. In any referral for collection or action against the Client for monies due to Estore Company , upon recovery by Estore Company , the Client shall pay the expenses of collection and/or litigation, including a reasonable attorney fee, plus an additional eighteen percent (18%) interest per annum or the maximum permitted by law. In the event an invoice is ten (10) days or more overdue, Estore Company will (a) remove any project under development from the active production schedule and place it on-hold; and (b) suspend any hosting services provided by Estore Company . In the event Client remits to Estore Company  the total past due balance (including any interest owed thereon), Estore Company  may, in its sole discretion, (x) re-launch any project under development within the production schedule at the first available opening, and (y) resume any suspended hosting services, provided that Client pays such reactivation fee as Estore Company , in its sole discretion, shall assess. 
</p>

<h2>Termination </h2>
<p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. 
Any services rendered as part of any agreement with Estorecompany you will be liable regardless of contract termination. All Service charges must be paid in order to retain the rights on delivered services/. Products.</p>
<p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof). </p>

<h2>SEVERABILITY</h2>
<p>
The provisions of this Agreement shall be severable, and if a court of competent jurisdiction holds any provisions of this Agreement invalid, illegal or unenforceable, the remaining provisions shall nevertheless remain in full force and effect as written. 
</p>
<h2>COMPLIANCE WITH LAWS </h2>
<p>
The parties agree to comply with all applicable laws, treaties, conventions, directives, statutes, ordinances, rules, regulations, orders, writs, judgments, injunctions or decrees of any governmental authority having jurisdiction ("Laws") pertaining to the fulfillment of the Agreement. The Client will be responsible for compliance with all Laws applicable to the project outlined within the SOW or Proposal. If any license or consent of any government or other authority is required for the acquisition, carriage or use of the project outlined, The Client will obtain the same at its expense, and if necessary, provide evidence of the same to Estore Company  on request. Failure to do so will entitle Estore Company  to withhold or delay the project outlined herein, but failure to do so will not entitle The Client to withhold or delay payment of the price therefore. Any expenses or charges incurred by Estore Company  as a result from such failure will be paid for by The Client within 10 days of receipt of Estore Company 's written request.
</p>
<h2>GOVERNING LAW/JURISDICTION </h2>
<p>
This Agreement and the relationship between the parties shall be governed by and construed in accordance with the laws of the State of Ohio (without regard to the law of conflict of any jurisdiction). The Client consents to personal jurisdiction of all state and federal courts in Cleveland, Ohio in connection with any dispute arising hereunder. The parties agree that either or both of them may file a copy of this Section with any court as written evidence of the knowing, voluntary and bargained agreement between the parties irrevocably to waive any objections to venue or to convenience of forum. 
</p>
<h2>ARBITRATION</h2>
<p>
Any controversy of claim arising out of or in connection with the negotiation, execution and/or performance of this Agreement (other than the failure by The Client to pay for product or for services provided when due hereunder) shall be submitted to arbitration in accordance with the then current rules of the American Arbitration Association. The arbitration shall be conducted in Cleveland, Ohio, or at such other place as the parties may agree upon, by one person independent of the parties appointed by them by mutual agreement (the "Arbitrator"). Failing the appointment of an Arbitrator by the parties, such Arbitrator shall be appointed by the president of the American Arbitration Association or his/her designee. Judgment on any award may be entered in any court having jurisdiction as herein provided. Notice of process in connection with arbitral or judicial proceedings may be served upon the parties by registered or certified mail, with the same effect as if personally served. Any money awards shall be expressed in United States currency and shall include a reasonable interest component. The costs of any such arbitration proceeding shall be paid by the party against which the award is rendered.
</p>
<h2>COMPLETION OF THE PROJECT </h2>
<p>Estore Company 's estimated time for completion of the project is an estimate only and it shall not be liable for delays in the performance of, or the non-performance of, any of its obligations under this Agreement. </p>
<h2>INDEPENDENT CONTRACTOR/OWNERSHIP RIGHTS </h2>
<p>
Estore Company  shall perform such services outlined in the SOW or Proposal and under this Agreement as an independent contractor and not as an agent or employee of The Client. The Client acknowledges and agrees that the services performed and any resulting applications created (hereinafter referred to as "the Works"), and all rights therein, including, without limitation, copyright, patent, and trademark, belongs to and shall be the sole and exclusive property of Estore Company , and that Estore Company  is the author of the Works. The Client acknowledges and agrees that the resulting applications may comprise two separate and distinct components: the Code including, but not limited to, the source code, object code, or machine code associated with the resulting applications; and, the Screen Displays which include the viewable images produced or displayed as a result of executing the code. Estore Company  hereby sells, assigns, and transfers to The Client, its successors and assigns, the entire right, title and interest in and to the copyright in the Screen Displays. Estore Company  hereby grants The Client a non-exclusive, limited, non-transferrable license in the Code to create non-competitive derivative works for use solely by The Client. Non-competitive derivative works shall refer to adaptations, alterations, modifications, updates, or any other changes made to the Code for use by The Client internally and not for distribution, sale, transfer, or any other purpose that may be adverse to Estore Company 's rights in the Works. Upon receiving final payment, Estore Company  will provide The Client a copy of all final, approved design and code structure files. Final, approved design and code structure files will be made available to The Client upon request and delivered by electronic format. 
</p>
<h2>ENTIRE AGREEMENT/AMENDMENT </h2>
<p>
The Agreement constitutes the entire understanding and agreement between the parties with respect to the subject matter hereof and supersedes any and all prior or contemporaneous agreements or understandings between the parties, whether in writing or orally. This Agreement may not be amended except in writing signed by the parties hereto.
</p>
</div>

</section>


</Layout>


    )


}


export default termsConditions